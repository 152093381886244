const ja = {
    "i18n_圖靈科技": "Turing テック有限会社",

    "i18n_首頁": "ホーム",
    "i18n_產品": "製品",
    "i18n_語言": "言語",
    "i18n_關於": "会社概要",
    "i18n_連結": "リンク",

    "i18n_聯絡我們": "お問い合わせ",
    "i18n_聯絡資訊": "連絡先",
    "i18n_其他資訊": "その他の情報",
    "i18n_營業時間": "営業時間",

    "i18n_公司地址": "所在地: No. 3, Aly. 5, Ln. 620, Sec. 2, Datong Rd., South Dist., Tainan City 702003 , Taiwan (R.O.C.)",

    "i18n_致力帶給客戶最好的體驗": "最高の顧客体験を提供することに尽力している会社",

    "i18n_首頁介紹文標題1-1": "革新。",
    "i18n_首頁介紹文標題1-2": "金型業界の未来をリードします。",
    "i18n_首頁介紹文內容1": "当社は創業以来、お客様のニーズに最適な技術サービスの提供と、設備能力向上のプロジェクト評価に取り組んでまいりました。 機械と電気の統合による成果が得られ、将来的にはソフトウェア開発とサービスに移行する予定です。",
    "i18n_首頁介紹文標題2-1": "堅実な専門基礎。",
    "i18n_首頁介紹文標題2-2": "優れた品質保証。",
    "i18n_首頁介紹文內容2": "強固な専門的基盤と優れた品質保証により、当社はお客様に一歩ずつ証明していきます。 私たちは情熱と経験豊富なチームであり、クライアントの高い要求を満たすためにあらゆる困難を排除します。",
    "i18n_首頁介紹文標題3-1": "より良い未来を作ります。",
    "i18n_首頁介紹文標題3-2": "私たちは、輝かしい未来を築くために協力する志を同じくするパートナーを心から歓迎します。",
    "i18n_首頁介紹文內容3": "私たちは、共創することによってのみ、Win-Win の状況を目指して協力できると強く信じています。 顧客、サプライヤー、パートナーとの協力において、私たちは常に誠実さ、共有、革新という核となる価値観を守ります。 緊密な協力を通じて、私たちは手を携え、目標のあらゆる達成の成果を分かち合いましょう。",

    "i18n_關於介紹文標題": "私たちはゼロからスタートし、各人の不屈の努力によって、個々が投じた情熱と知恵を結集させ、完全な円環として束ね上げ、それによってチューリングテクノロジー株式会社の独自のブランドロゴが生まれました。",
    "i18n_關於介紹文引言": "All is from scratch, just like us, be happying and keeping to do everything you love, you'll get somewhere.",
    "i18n_關於介紹文創辦人": "Turing テックの創設者",
    "i18n_關於介紹文內容1": "ご縁があり、私たちはチューリングテクノロジー株式会社を設立し、半導体製造装置および金型関連産業に専念することといたしました。 私たちの分野は半導体ですが、私たちの哲学はそれをはるかに超えています。 私たちが冷徹な専門知識を伝えるだけでなく、人間味と独特の質感を備えたテクノロジー企業であることを、すべてのお客様に感じていただければ幸いです。",
    "i18n_關於介紹文內容2": "急速な業績成長を追求する今日の競争の激しい環境において、私たちは落ち着いて一歩を着実に進めることを選択します。 私たちは、あらゆる積み重ねが卓越性への一歩であり、あらゆる努力が完璧な工芸品であると固く信じています。 私たちは究極を重視し、卓越性を追求し、常に技術革新に挑戦するだけでなく、無限のサービス価値を創造します。",
    "i18n_關於介紹文內容3": "お客様と対話するたびに、私たちは品質と細部へのこだわりを示します。 これは、お客様に対する当社の取り組みであり、当社自身に対する厳しい要件でもあります。 この理念に基づいて、私たちはお客様の信頼と尊敬を勝ち取ることができます。",
    "i18n_關於介紹文內容4": "私たちの物語は始まったばかりです。私たちは急いでいませんが、しっかりと前に進んでいます。 私たちは、継続的な改善、学習、革新によってのみ、この変化し続ける世界においてお客様に永続的な価値を創造し、より良い未来を創造するために協力できると信じています。",

    "i18n_Footer文字": "2023年に設立された私たちは、品質と革新を核とした価値観を追求しています。専門の技術的な解決策を提供するだけでなく、人文的なケアを技術に統合しています。",
};

export default ja;