import { Fade } from "react-reveal";
import { FormattedMessage } from "react-intl";
import { Col, Container, Row } from "react-bootstrap";

import Banner from "../Component/Banner";

import '../CSS/HomePage.css';

// react-reveal
// https://www.react-reveal.com/examples/common/fade/

function Home() {
    return (
        <>
            <Banner image="./images/banner.jpg" />
            <Container>
                <Fade bottom>
                    <Container className="mt-4">
                        <Row>
                            <Col md={7}>
                                <h1 className="fw-normal"><FormattedMessage id="i18n_首頁介紹文標題1-1" /><span className="text-secondary"><FormattedMessage id="i18n_首頁介紹文標題1-2" /></span></h1>
                                <p className="lead"><FormattedMessage id="i18n_首頁介紹文內容1" /></p>
                            </Col>
                            <Col md={5}>
                                <img src="./images/home-image-1.jpg" width="500px" height="500px" className="img-fluid" alt="home-image-1" />
                            </Col>
                        </Row>
                    </Container>
                </Fade>

                <hr className="homepage-divider" />

                <Fade left>
                    <Container>
                        <Row>
                            <Col md={7} className="order-md-2">
                                <h1 className="fw-normal"><FormattedMessage id="i18n_首頁介紹文標題2-1" /><span className="text-secondary"><FormattedMessage id="i18n_首頁介紹文標題2-2" /></span></h1>
                                <p className="lead"><FormattedMessage id="i18n_首頁介紹文內容2" /></p>
                            </Col>
                            <Col md={5} className="order-md-1">
                                <img src="./images/home-image-2.jpg" width="500px" height="500px" className="img-fluid" alt="home-image-2" />
                            </Col>
                        </Row>
                    </Container>
                </Fade>

                <hr className="homepage-divider" />

                <Fade right>
                    <Container>
                        <Row>
                            <Col md={7}>
                                <h1 className="fw-normal"><FormattedMessage id="i18n_首頁介紹文標題3-1" /><span className="text-secondary"><FormattedMessage id="i18n_首頁介紹文標題3-2" /></span></h1>
                                <p className="lead"><FormattedMessage id="i18n_首頁介紹文內容3" /></p>
                            </Col>
                            <Col md={5}>
                                <img src="./images/home-image-3.jpg" width="500px" height="500px" className="img-fluid" alt="home-image-3" />
                            </Col>
                        </Row>
                    </Container>
                </Fade>
            </Container>
        </>
    );
}

export default Home;