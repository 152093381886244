import { Quote } from "react-bootstrap-icons";
import { FormattedMessage } from "react-intl";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";

function AboutPage() {
    return (
        <>
            <Container className="mt-4">
                <h1 className="display-5"><FormattedMessage id="i18n_關於" /></h1>
                <hr />
                <p className="lead"><FormattedMessage id="i18n_關於介紹文標題" /></p>
                <hr />

                <Row>
                    <Col md={3}></Col>
                    <Col md={6}>
                        <Card className="text-center m-4">
                            <Card.Body>
                                <p><Quote /> <FormattedMessage id="i18n_關於介紹文引言" /></p>
                                <hr />
                                <p className="text-secondary mb-0">— <FormattedMessage id="i18n_關於介紹文創辦人" /><br /><i>W.S.</i></p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}></Col>
                </Row>

                <Fade up>
                    <p><FormattedMessage id="i18n_關於介紹文內容1" /></p>

                    <p><FormattedMessage id="i18n_關於介紹文內容2" /></p>

                    <p><FormattedMessage id="i18n_關於介紹文內容3" /></p>

                    <p><FormattedMessage id="i18n_關於介紹文內容4" /></p>
                </Fade>

            </Container>
        </>
    );
}

export default AboutPage;