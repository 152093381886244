import { Outlet } from "react-router";

import Footer from './Footer.js';
import MainNavbar from "./MainNavbar.js";

function Layout() {
    return (
        <>
            <MainNavbar />

            <Outlet />

            <Footer />
        </>
    );
}

export default Layout;