import { FormattedMessage } from 'react-intl';
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';

function setLanguage(language) {
    document.cookie = "i18n=" + language;
    window.location.reload();
}

function MainNavbar() {
    return (
        <>
            <Navbar expand="lg" fixed='top' className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="/"><img src="./images/logo.png" style={{ objectFit: "cover", height: "40px" }} alt="logo-image" /> <FormattedMessage id="i18n_圖靈科技" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/"><FormattedMessage id="i18n_首頁" /></Nav.Link>
                            {/* <Nav.Link href="/product"><FormattedMessage id="i18n_產品" /></Nav.Link> */}
                            <Nav.Link href="/contact"><FormattedMessage id="i18n_聯絡我們" /></Nav.Link>
                            <Nav.Link href="/about"><FormattedMessage id="i18n_關於" /></Nav.Link>
                        </Nav>
                        <Nav>
                            <NavDropdown title={<FormattedMessage id="i18n_語言" />} id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={() => setLanguage("zh")}>中文</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => setLanguage("en")}>English</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => setLanguage("ja")}>日本語</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default MainNavbar;