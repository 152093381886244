import { Container } from "react-bootstrap";

function ErrorPage(props) {
    let content, code = props.code;

    switch(code) {
        case 404:
            content = "Page Not Found.";
            break;

        default:
            content = "Somethin Wrong.";
            break;
    }

    return (
        <>
            <Container className='text-center'>
                <div className='display-1'>{code}</div>
                <div className='error-content-text lead'>{content}</div>
            </Container>
        </>
    );
}

export default ErrorPage;