import { FormattedMessage } from "react-intl";
import { Col, Container, Row } from "react-bootstrap";

import '../CSS/Footer.css';

function Footer() {
    return (
        <>
            <footer className='w-100 py-4 flex-shrink-0 mt-4 bg-body-tertiary footer-footer'>
                <Container className='p-4 bg-body-tertiary'>
                    <Row className='gy-4 gx-5'>
                        <Col md={12} lg={6}>
                            <h2 className='layout-footer-title'><FormattedMessage id="i18n_圖靈科技" /></h2>
                            <p className="small text-muted"><FormattedMessage id="i18n_Footer文字" /></p>
                            <p className="small text-muted mb-0">&copy; 2023 Turing Tech Ltd. All rights reserved.</p>
                        </Col>
                        <Col md={12} lg={3}>
                            <h3 className="mb-3"><FormattedMessage id="i18n_聯絡資訊" /></h3>
                            <ul className="list-unstyled text-muted">
                                <li><a href="tel:062690003" className="text-decoration-none text-dark">Tel: (06)-269-0003</a></li>
                                <li><a href="fax:062690003" className="text-decoration-none text-dark">Fax: (06)-269-0003</a></li>
                                <li><a href="tel:0928693709" className="text-decoration-none text-dark">Phone: 09-2869-3709</a></li>
                                <li><a href="mailto:turing@tulimit.com" className="text-decoration-none text-dark">E-Mail: turing@tulimit.com</a></li>
                            </ul>
                        </Col>
                        <Col xs={12} lg={3}>
                            <h3 className="mb-3"><FormattedMessage id="i18n_連結" /></h3>
                            <ul className="list-unstyled text-muted">
                                <li><a href='/' className='text-decoration-none text-dark'><FormattedMessage id="i18n_首頁" /></a></li>
                                <li><a href='/about' className='text-decoration-none text-dark'><FormattedMessage id="i18n_關於" /></a></li>
                                <li><a href='/contact' className='text-decoration-none text-dark'><FormattedMessage id="i18n_聯絡我們" /></a></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}

export default Footer;