const en = {
    "i18n_圖靈科技": "Turing Tech Ltd.",

    "i18n_首頁": "Home",
    "i18n_產品": "Products",
    "i18n_語言": "Language",
    "i18n_關於": "About",
    "i18n_連結": "Links",

    "i18n_聯絡我們": "Contact Us",
    "i18n_聯絡資訊": "Contact Imformation",
    "i18n_其他資訊": "Business Information",
    "i18n_營業時間": "Business Hours",

    "i18n_公司地址": "Address: No. 3, Aly. 5, Ln. 620, Sec. 2, Datong Rd., South Dist., Tainan City 702003 , Taiwan (R.O.C.)",

    "i18n_致力帶給客戶最好的體驗": "Committed to An Exceptional Customer Experience",

    "i18n_首頁介紹文標題1-1": "Innovation. ",
    "i18n_首頁介紹文標題1-2": "Lead the future of the mold industry.",
    "i18n_首頁介紹文內容1": "Since its establishment, we have been committed to providing technical services that best meet customer needs, and project evaluation of equipment capacity improvement. We can achieve mechanical and electrical integration output, and will move towards software development and services in the future.",
    "i18n_首頁介紹文標題2-1": "Solid professional foundation. ",
    "i18n_首頁介紹文標題2-2": "Excellent quality assurance.",
    "i18n_首頁介紹文內容2": "With our solid professional foundation and excellent quality assurance, we prove to our customers step by step. We are passionate and experienced team, we will rule out any difficulties to meet the high demands of our clients.",
    "i18n_首頁介紹文標題3-1": "To create the future together.",
    "i18n_首頁介紹文標題3-2": "We sincerely welcome like-minded partners to work together to build a wonderful future.",
    "i18n_首頁介紹文內容3": "We firmly believe that only by co-creating can we work together for a win-win situation. In the cooperation with customers, suppliers and partners, we always uphold the core values ​​of integrity, sharing and innovation. Through close cooperation, let us join hands and share the fruits of every achievement of our goals.",

    "i18n_關於介紹文標題": "We started from scratch, and through everyone's unremitting efforts, we gathered their respective efforts and wisdom into a complete circle, thus the unique brand logo of Turing Tech Ltd. was born.",
    "i18n_關於介紹文引言": "All is from scratch, just like us, be happying and keeping to do everything you love, you'll get somewhere.",
    "i18n_關於介紹文創辦人": "Founder of Turing Tech",
    "i18n_關於介紹文內容1": "By chance, we ushered in a rare opportunity. We decided to establish Turing Technology Co., Ltd. and devote ourselves to the semiconductor equipment and mold related industries. Although our field is semiconductors, our philosophy goes far beyond that. We hope that every customer can feel that we are not only a carrier of cold professional knowledge, but also a technology company with humanistic feelings and unique texture.",
    "i18n_關於介紹文內容2": "In today's highly competitive environment in pursuit of rapid performance growth, we choose to remain calm and take every step steadily. We firmly believe that every accumulation is a step towards excellence, and every effort is a perfect craft. We focus on the ultimate, pursue excellence, not only constantly challenge ourselves in technological innovation, but also create infinite value in services.",
    "i18n_關於介紹文內容3": "Every time we interact with customers, we show our persistence in quality and details. This is our commitment to our customers and our strict requirements on ourselves. Based on this concept, we can win the trust and respect of customers.",
    "i18n_關於介紹文內容4": "Our story has just begun, we are not in a hurry, but firmly moving forward. We believe that only by continuous improvement, learning and innovation, can we create lasting value for our customers in this ever-changing world and work together to create a better future.",

    "i18n_Footer文字": "Established in 2023, we are dedicated to quality and innovation as our core values. We not only offer professional technical solutions, but also integrate humanistic care into technology.",
};

export default en;