import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import en from './lang/en.js';
import zh from './lang/zh.js';
import ja from './lang/ja.js';

import React from 'react';
import ReactDOM from 'react-dom/client';
import HomePage from './Page/HomePage.js';
import Layout from './Component/Layout.js';
import AboutPage from './Page/AboutPage.js';
import ErrorPage from './Page/ErrorPage.js';
import AboutUsPage from './Page/AboutUsPage.js';
import ContactPage from './Page/ContactPage.js';
import ProductPage from './Page/ProductPage.js';
import reportWebVitals from './reportWebVitals';

import './CSS/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Setting Language.
// https://ithelp.ithome.com.tw/articles/10290078
const local = navigator.language;
let message = zh, setted = false;

// Check if there is language setting in cookie.
for (let cookie of document.cookie.split(";")) {
    cookie = cookie.trim();

    if (cookie.includes("i18n")) {
        cookie = cookie.slice(5);
        setted = true;

        if (cookie === "zh") message = zh;
        else if (cookie === "en") message = en;
        else if (cookie === "ja") message = ja;
        else setted = false;
        
        break;
    }
}

// If language is not setted, using defualt language.
if (!setted) {
    if (local.includes("zh")) message = zh;
    else if (local.includes("en")) message = en;
    else if (local.includes("ja")) message = ja;
}

root.render(
    <IntlProvider locale={local} defaultLocale="en" messages={message}>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route path='/product' element={<ProductPage />} />
                    <Route path='/contact' element={<ContactPage />} />
                    <Route path='/about' element={<AboutPage />} />
                    <Route path='/aboutus' element={<AboutUsPage />} /> {/* SEO Page */}
                    <Route path='*' element={<ErrorPage code={404} />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </IntlProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
