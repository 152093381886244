import { Carousel, Image } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

function Banner(props) {
    return (
        <>
            <Carousel>
                <Carousel.Item>
                    {/* Show no mobile. */}
                    <Image src={props.image} width="100%" className="d-block d-sm-none" style={{ height: "300px", objectFit: "cover" }} alt="banner-image-mobile" />

                    {/* Show no desktop. */}
                    <Image src={props.image} width="100%" className="d-none d-sm-block" style={{ height: "400px", objectFit: "cover" }} alt="banner-image-desktop" />

                    <Carousel.Caption>
                        <h1 className="display-3"><FormattedMessage id="i18n_圖靈科技" /></h1>
                        <p className="fs-5"><FormattedMessage id="i18n_致力帶給客戶最好的體驗" /></p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </>
    );
}

export default Banner;