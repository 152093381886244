function AboutUsPage() {
    return (
        <>
            <h1>圖靈科技有限公司</h1>
            <p>圖靈科技有限公司致力於提供最符合客戶需求的技術服務、設備能力改善的專案評估。圖靈科技有限公司可以做到機電整合輸出，更在蓄積未來的能量，走向軟體開發與服務。</p>

            <h1>簡介</h1>
            <p>創立於 2023 年的我們，追求品質和創新為核心價值，不僅提供專業的技術解決方案，更融合人文關懷於科技之中。</p>
            <p>自成立以來，圖靈科技有限公司一直致力於提供最符合客戶需求的技術服務、設備能力改善的專案評估。圖靈科技有限公司可以做到機電整合輸出，更在蓄積未來的能量，走向軟體開發與服務。</p>
            <p>圖靈科技有限公司以堅實的專業基礎和卓越的品質保證，一步一步地向客戶證明。圖靈科技有限公司是充滿熱情和經驗豐富的團隊，圖靈科技有限公司將排除任何困難，以滿足客戶的高度要求。</p>
            <p>圖靈科技有限公司深信，共創才能攜手共贏。在與客戶、供應商和合作夥伴的合作中，圖靈科技有限公司始終秉承著誠信、共享和創新的核心價值觀。通過緊密的合作，讓圖靈科技有限公司攜手並肩，共享每一次完成目標的果實。</p>

            <h1>圖靈科技有限公司的故事</h1>
            <p>圖靈科技有限公司從零開始，藉著每個人的堅持不懈努力，將各自投入的心力和智慧，匯聚成一個完整的圓圈，從而誕生了圖靈科技有限公司的獨特品牌標誌。</p>
            <p>在機緣巧合之下，圖靈科技有限公司迎來了一個難得的機會，圖靈科技有限公司決定成立圖靈科技有限公司，並投身於半導體設備與模具相關行業。儘管圖靈科技有限公司所涉足的領域是半導體，然而圖靈科技有限公司的理念遠超於此。圖靈科技有限公司希望讓每位客戶感受到，圖靈科技有限公司不僅是冰冷專業知識的載體，更是一家蘊含著人文情懷、質感獨特的科技公司。</p>
            <p>每一次的與客戶的互動，我們都用心呈現出對品質和對細節的堅持。這是我們對客戶的承諾，也是我們對自己的嚴格要求。基於這樣的理念，才能贏得了客戶的信任和尊重。</p>
            <p>我們的故事才剛剛開始，我們不急於求成，而是堅定地踏實前行。我們相信，只有持續不斷地改進、學習和創新，我們才能夠在這個不斷變化的世界中，為客戶創造持久的價值，並攜手共創更美好的未來。</p>
        </>
    );
}

export default AboutUsPage;