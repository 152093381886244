const zh = {
    "i18n_圖靈科技": "圖靈科技有限公司",

    "i18n_首頁": "首頁",
    "i18n_產品": "產品",
    "i18n_語言": "語言",
    "i18n_關於": "關於",
    "i18n_連結": "連結",

    "i18n_聯絡我們": "聯絡我們",
    "i18n_聯絡資訊": "聯絡資訊",
    "i18n_其他資訊": "其他資訊",
    "i18n_營業時間": "營業時間",

    "i18n_公司地址": "公司地址: 臺南市南區大同路二段620巷5弄3號",

    "i18n_致力帶給客戶最好的體驗": "致力帶給客戶最好的體驗",

    "i18n_首頁介紹文標題1-1": "開拓創新。",
    "i18n_首頁介紹文標題1-2": "開創無限可能的未來。",
    "i18n_首頁介紹文內容1": "自成立以來，我們一直致力於提供最符合客戶需求的技術服務、設備能力改善的專案評估。我們可以做到機電整合輸出，更在蓄積未來的能量，走向軟體開發與服務。",
    "i18n_首頁介紹文標題2-1": "堅實的專業基礎。",
    "i18n_首頁介紹文標題2-2": "卓越品質保證。",
    "i18n_首頁介紹文內容2": "我們以堅實的專業基礎和卓越的品質保證，一步一步地向客戶證明。我們是充滿熱情和經驗豐富的團隊，我們將排除任何困難，以滿足客戶的高度要求。",
    "i18n_首頁介紹文標題3-1": "共創未來。",
    "i18n_首頁介紹文標題3-2": "我們竭誠歡迎志同道合的夥伴，一同合作，建構出精彩的未來。",
    "i18n_首頁介紹文內容3": "我們深信，共創才能攜手共贏。在與客戶、供應商和合作夥伴的合作中，我們始終秉承著誠信、共享和創新的核心價值觀。通過緊密的合作，讓我們攜手並肩，共享每一次完成目標的果實。",

    "i18n_關於介紹文標題": "我們從零開始，藉著每個人的堅持不懈努力，將各自投入的心力和智慧，匯聚成一個完整的圓圈，從而誕生了圖靈科技有限公司的獨特品牌標誌。",
    "i18n_關於介紹文引言": "All is from scratch, just like us, be happying and keeping to do everything you love, you'll get somewhere.",
    "i18n_關於介紹文創辦人": "圖靈科技創辦人",
    "i18n_關於介紹文內容1": "在機緣巧合之下，我們迎來了一個難得的機會，我們決定成立圖靈科技有限公司，並投身於半導體設備與模具相關行業。儘管我們所涉足的領域是半導體，然而我們的理念遠超於此。我們希望讓每位客戶感受到，我們不僅是冰冷專業知識的載體，更是一家蘊含著人文情懷、質感獨特的科技公司。",
    "i18n_關於介紹文內容2": "在當今追求業績快速增長的競爭激烈環境中，我們選擇保持冷靜，穩定地邁出每一步。我們堅信，每一次的積累都是邁向卓越的腳步，每一次的努力都是磨礪出完美的工藝。我們專注於極致，追求卓越，不僅在技術創新上不斷挑戰自我，更在服務中創造無限價值。",
    "i18n_關於介紹文內容3": "每一次的與客戶的互動，我們都用心呈現出對品質和對細節的堅持。這是我們對客戶的承諾，也是我們對自己的嚴格要求。基於這樣的理念，才能贏得了客戶的信任和尊重。",
    "i18n_關於介紹文內容4": "我們的故事才剛剛開始，我們不急於求成，而是堅定地踏實前行。我們相信，只有持續不斷地改進、學習和創新，我們才能夠在這個不斷變化的世界中，為客戶創造持久的價值，並攜手共創更美好的未來。",

    "i18n_Footer文字": "創立於 2023 年的我們，追求品質和創新為核心價值，不僅提供專業的技術解決方案，更融合人文關懷於科技之中。",
};

export default zh;

