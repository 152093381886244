import { FormattedMessage } from "react-intl";
import { Card, Col, Container, Row } from "react-bootstrap";
import { InfoCircle, TelephoneForward } from "react-bootstrap-icons";

function ContactPage() {
    return (
        <>
            <Container className="mt-4">
                <h1 className="display-5"><FormattedMessage id="i18n_聯絡我們" /></h1>
                <hr />
                <Row>
                    <Col md={6} xs={12} className="my-1">
                        <Card border="0">
                            <Card.Body>
                                <h3><TelephoneForward /> <FormattedMessage id="i18n_聯絡資訊" /></h3>
                                <ul className="lead">
                                    <li><a href="tel:062690003" className="text-decoration-none">Tel: (06)-269-0003</a></li>
                                    <li><a href="fax:062690003" className="text-decoration-none">Fax: (06)-269-0003</a></li>
                                    <li><a href="tel:0928693709" className="text-decoration-none">Phone: 09-2869-3709</a></li>
                                    <li><a href="mailto:turing@tulimit.com" className="text-decoration-none">E-Mail: turing@tulimit.com</a></li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} xs={12} className="my-1">
                        <Card border="0">
                            <Card.Body>
                                <h3><InfoCircle /> <FormattedMessage id="i18n_其他資訊" /></h3>
                                <ul className="lead">
                                    <li><FormattedMessage id="i18n_營業時間" />: 09:00 ~ 18:00</li>
                                    <li><FormattedMessage id="i18n_公司地址" /></li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ContactPage;